<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="poste_details" v-if="pageIsLoaded">
            <div class="title_wrap">
                <div class="left">
                    <h2 class="title small">{{ $t('OPPORTUNITÉ DE CARRIÈRE') }}</h2>
                    <h1 class="title medium"><span v-html="content.title"></span></h1>
                </div>
                <div class="right">
                    <p class="regular-text">
                        <strong v-html="content.detailsDuPosteApi.facility_name"></strong>:
                        <span v-html="content.detailsDuPosteApi.facility_city"></span>,
                        <span v-html="content.detailsDuPosteApi.facility_province"></span>,
                        <span v-html="content.detailsDuPosteApi.facility_country"></span>
                    </p>
                </div>
            </div>
        </section>

        <section class="txt_row small-padding-bottom wysiwyg" id="next" v-if="pageIsLoaded">
            <div class="row">
                <p class="regular-text">
                    <span v-html="content.descriptionHtml"></span>
                </p>
            </div>
        </section>

        <section class="form_wrap" v-if="pageIsLoaded">
            <div class="form">
                <h2 class="title medium">{{ $t('Appliquer maintenant') }}</h2>
                <form
                    action=""
                    name="Postuler"
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                    enctype="multipart/form-data"
                >
                    <input
                        type="text"
                        :placeholder="$t('Prénom')"
                        class="input"
                        name="prenom"
                        v-model="formData.prenom"
                        required
                    />
                    <input
                        type="text"
                        :placeholder="$t('Nom')"
                        class="input"
                        name="nom"
                        v-model="formData.nom"
                        required
                    />
                    <input
                        type="text"
                        :placeholder="$t('Téléphone')"
                        class="input"
                        name="telephone"
                        v-model="formData.telephone"
                        required
                    />
                    <input
                        type="email"
                        placeholder="Courriel"
                        class="input"
                        name="courriel"
                        v-model="formData.courriel"
                        required
                    />
                    <select v-model="formData.source" id="select-sourcing" class="input input-select" name="source" placeholder="Où avez-vous entendu parler de nous?">
                        <option value="" selected>Où avez-vous entendu parler de nous?</option>
                        <option value="1">Jobillico</option>
                        <option value="2">Jobboom</option>
                        <option value="3">Placement Emploi-Québec</option>
                        <option value="4">Indeed</option>
                        <option value="5">LinkedIn</option>
                        <option value="6">Facebook</option>
                        <option value="7">Site carrière</option>
                        <option value="8">Journaux</option>
                        <option value="9">Bouche-à-oreille</option>
                        <option value="10">Hôtellerie Jobs</option>
                        <option value="11">Site spécialisé</option>
                        <option value="12">Neuvo</option>
                        <option value="13">Cégep</option>
                        <option value="14">Université</option>
                        <option value="15">Référence d'un employé</option>
                        <option value="16">Événement carrière</option>
                        <option value="17">Jobsmedia</option>
                        <option value="18">Publicité radio</option>
                        <option value="19">En personne/à la réception</option>
                        <option value="20">Recruteur externe</option>
                    </select>
                    <textarea
                        :placeholder="$t('Message')"
                        class="input textarea"
                        name="message"
                        v-model="formData.message"

                    ></textarea>

                    <!-- <p class="regular-text">{{ $t('Cartes et formations détenues') }}</p>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('Carte de compétence de la CCQ')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">{{ $t('Carte de compétence de la CCQ') }}</p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('Utilisation sécuritaire des plates-formes')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">
                            {{ $t('Utilisation sécuritaire des plates-formes') }}
                        </p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('DDD')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">{{ $t('Prévention des chutes (ASP)') }}</p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('Utilisation sécuritaire des échafaudages à cadres métalliques (ASP)')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">
                            {{
                                $t(
                                    'Utilisation sécuritaire des échafaudages à cadres métalliques (ASP)'
                                )
                            }}
                        </p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('Cours reconnu de cariste (chariot-élévateur)')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">
                            {{ $t('Cours reconnu de cariste (chariot-élévateur)') }}
                        </p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('SIMDUT 2015 (ASP)')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">{{ $t('SIMDUT 2015 (ASP)') }}</p>
                    </div>
                    <div class="checkbox_wrap">
                        <input type="checkbox" name="formations[]" v-model="formData.formations" :value="$t('Garantie de 150 heures demandée')" />
                        <span class="checkmark"></span>
                        <p class="regular-text">{{ $t('Garantie de 150 heures demandée') }}</p>
                    </div> -->

                    <div class="input_file">
                        <p class="regular-text">{{ $t('Curriculum vitea') }}</p>
                        <img src="@/assets/img/plus.svg" alt="plus" />

                        <div class="input-file">
                            <input
                                id="file"
                                type="file"
                                name="file"
                                class="inputfile"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, text/plain, .pdf"
                                @change="uploadFile($event)"
                            />
                        </div>
                        <p class="helper" v-if="fileName">
                            <small>{{ fileName }}</small>
                        </p>
                    </div>
                    <!-- <div class="input_file">
                        <p class="regular-text">Autre document</p>
                        <img src="@/assets/img/plus.svg" alt="plus" />
                    </div> -->
                    <button class="cta">
                        <p class="regular-text">{{ $t('Envoyer') }}</p>
                        <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                    </button>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

// import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            isSending: false,
            formMessage: '',
            formMessageStatus: '',
            fileName: '',
            formData: {
                nom: '',
                prenom: '',
                telephone: '',
                courriel: '',
                source: '',
                message: ''
            },
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.lightboxelement = GLightbox
                        // GLightbox()

                        setTimeout(() => {
                            // // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async uploadFile(event) {
            const fileName = event.target.files[0].name
            this.formData.file = fileName
            this.fileName = fileName
            // Handling file
            this.formData.cv = event.target.files[0]
        },
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['action'] = 'contact-form/send'
            this.formData['formName'] = 'Postuler'
            this.formData['sujet'] = 'Postuler'

            const formData = new FormData()
            formData.append('cv', this.formData.cv)

            formData.append('prenom', this.formData.prenom)
            formData.append('nom', this.formData.nom)
            formData.append('telephone', this.formData.telephone)
            formData.append('courriel', this.formData.courriel)
            formData.append('source', this.formData.source)
            formData.append('message', this.formData.message)

            formData.append('poste', this.content.jobid)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/create-job-offer`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre demande a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    setTimeout(() => {
                        this.formData = {}
                        this.fileName = ''

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formData = {
                            fromName: '',
                            fromEmail: '',
                            phone: '',
                            subject: '',
                            source: '',
                            message: '',
                        }
                        this.fileName = ''

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.helper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    color: #fff;
    line-height: 1;
    padding: 0;
    margin: 0;
    padding-right: 55px;
    z-index: 5;
}
</style>
